<template>
  <div :class="{container_sidebar: storeAuth.isAuthenticated}" class="container-sidebar">
    <div @click="resetHamburger" class="container-top">
      <img src="../../assets/shared/logo-mini.svg" alt="">
      <span v-if="storeSettings.systemSettingsGetters && storeSettings.systemSettingsGetters.avatar_first_name"
            class="text-avatar">{{ storeSettings.systemSettingsGetters.avatar_first_name.value }}</span>
    </div>
    <div class="container-all-links" v-if="storeAuth.isAuthenticated">
      <div @click="goToPath('/')"
           :class="{'container-single-link-active': $route.path === '/' || $route.name === 'detailIntent'}"
           class="container-single-link">
        <img src="../../assets/shared/routes.svg" alt="">
        <span :class="{'text-link-active': $route.path === '/' || $route.name === 'detailIntent'}" class="text-link">Percorsi</span>
        <img @click="setAdd('intent')" class="img-plus" v-if="$route.path === '/' || $route.name === 'detailIntent'"
             src="../../assets/shared/plus_active.svg" alt="">
        <img @click="setAdd('intent')" class="img-plus" v-else src="../../assets/shared/plus.svg" alt="">
      </div>
      <div @click="goToPath('/entities')"
           :class="{'container-single-link-active': $route.path === '/entities' || $route.name === 'detailEntity'}"
           class="container-single-link">
        <img src="../../assets/shared/entity.svg" alt="">
        <span :class="{'text-link-active': $route.path === '/entities' || $route.name === 'detailEntity'}"
              class="text-link">Entità</span>
        <img @click="setAdd('entity')" class="img-plus"
             v-if="$route.path === '/entities' || $route.name === 'detailEntity'"
             src="../../assets/shared/plus_active.svg" alt="">
        <img @click="setAdd('entity')" class="img-plus" v-else src="../../assets/shared/plus.svg" alt="">
      </div>

      <div class="container-divider"></div>

      <div @click="goToPath('/feedbacks')" :class="{'container-single-link-active': $route.path === '/feedbacks'}"
           class="container-single-link">
        <img src="../../assets/feedback/feedback.svg" alt="">
        <span :class="{'text-link-active': $route.path === '/feedbacks'}" class="text-link">Feedback</span>
      </div>
      <div @click="goToPath('/form-records')"
           :class="{'container-single-link-active': $route.path.substring(0,13) === '/form-records'}"
           class="container-single-link">
        <img src="../../assets/shared/form.svg" alt="">
        <span :class="{'text-link-active': $route.path.substring(0,13) === '/form-records'}" class="text-link">Form record</span>
      </div>
      <div @click="goToPath('/settings')" :class="{'container-single-link-active': $route.path === '/settings'}"
           class="container-single-link">
        <img src="../../assets/shared/settings.svg" alt="">
        <span :class="{'text-link-active': $route.path === '/settings'}" class="text-link">Impostazioni</span>
      </div>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useIntentStore} from "@/stores/intents";
import {useEntityStore} from "@/stores/entity";
import {useFormStore} from "@/stores/forms";
import {useDocumentStore} from "@/stores/documents";
import {useSettings} from "@/stores/settings";

export default {
  name: "ComponentSideBar",
  setup() {
    const storeAuth = useAuthStore()
    const storeApiSettings = useApiSettingStore()
    const storeSettings = useSettings()
    return {
      storeAuth,
      storeApiSettings,
      storeSettings
    }
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {
    resetAllAdd() {
      useFormStore().addForm = false
      useIntentStore().addIntent = false
      useEntityStore().addEntity = false
    },
    setAdd(str) {
      this.resetAllAdd()
      if (str === 'intent') {
        useIntentStore().addIntent = true
      } else if (str === 'entity') {
        useEntityStore().addEntity = true
      } else if (str === 'form') {
        useFormStore().addForm = true
      } else if (str === 'document') {
        useDocumentStore().addDocument = true
      }
    },
    resetHamburger() {
      if (useApiSettingStore().hamburgerStatusGetters) {
        useApiSettingStore().hamburgerStatus = false
        useApiSettingStore().overlayModal = false
      }
    },
    goToPath(path) {
      useApiSettingStore().overlayModal = false
      useApiSettingStore().hamburgerStatus = false
      this.$router.push({path: path}).catch(() => {
      })
    }
  },
  beforeUnmount() {
    this.emitter.off('clickMenu', () => {
    })
  }
}
</script>

<style scoped>
.text-link-active.text-link {
  font-weight: var(--font-weight-bold);
}

.container-single-link-active.container-single-link {
  background: #EAEDEE;
}

.container-single-link:hover {
  background: #EAEDEE;
}

.container-divider {
  width: 100%;
  height: 0.5px;
  margin: 25px 0px 35px 0px;
  border: 0.5px solid var(--color-light-grey);
}

.container_sidebar {
  border-right: 1px solid var(--color-light-grey);
}

.container-all-links {
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
}

.text-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--small);
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-black);
  margin-left: 10px;
  margin-right: auto;
}

.container-single-link img {
  width: 21px;
  height: 21px;
}

.container-single-link {
  display: flex;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 15px;
  align-items: center;
  border: 1px solid var(--color-light-grey);
  border-radius: 8px;
}

.text-avatar {
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--medium);
  display: flex;
  align-items: center;
  color: var(--color-black);
  margin-left: 10px;
}

.container-top {
  display: flex;
  background: var(--color-white);
  align-items: center;
}

.container-sidebar {
  display: flex;
  background: var(--color-white);
  flex-direction: column;
  /*position: fixed;*/
  min-width: 25vw;
  max-width: 25vw;
  padding: 20px 40px;
}

.container_sidebar {
  min-height: 100vh;
  max-width: 100vw;
  position: fixed;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-sidebar {
    display: none;
  }

  .container_sidebar {
    width: 100%;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}
</style>